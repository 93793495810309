import { useEffect } from 'react';

import { useSignInForm } from '@/features/authentication/signin/hooks/use-sign-in-form';
import { useSigninFormConfigContext } from '@/features/authentication/signin/providers/sign-in-form-context-provider';

import { GoogleButton, GoogleButtonProps } from '@/ui/components/social-button';

import { useGoogleSSO } from '@/lib/sso-service/providers/google';
import { SSOServiceProviderName } from '@/lib/sso-service/types';

export function SignInWithGoogle(props: Readonly<GoogleButtonProps>) {
  const { idToken, cleanup, googleSSO, redirectUri } = useGoogleSSO();
  const { config, setConfig } = useSigninFormConfigContext();
  const { handleSignIn } = useSignInForm({});

  function onClick() {
    googleSSO.signIn(redirectUri);
  }

  useEffect(() => {
    if (!idToken) return;

    setConfig({
      ...config,
      data: {
        ...config.data,
        isSSO: true,
        idToken,
        socialProvider: SSOServiceProviderName.Google,
      },
    });

    cleanup();
  }, [idToken]);

  useEffect(() => {
    if (
      !(
        config.data.idToken &&
        config.data.socialProvider === SSOServiceProviderName.Google
      )
    ) {
      return;
    }

    handleSignIn();
  }, [config.data.idToken, config.data.socialProvider]);

  return (
    <GoogleButton
      className={{
        root: 'border-neutral-s3 bg-neutral-s2 text-content-body-default font-semibold',
      }}
      {...props}
      onClick={onClick}
    />
  );
}
