export const API_ROUTES = {
  V1: {
    AUTH: {
      APPLE: {
        CALLBACK: '/api/v1/auth/apple/callback',
        get $CALLBACK() {
          return (redirect: string) => `${this.CALLBACK}?redirect=${redirect}`;
        },
      },
    },
  },
};
