import { notification } from '@kamona/components';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { API_ROUTES } from '@/shared/api-routes';
import { useHttpQuery, useSearchParams } from '@/shared/hooks';

import { identityApi } from '@/lib/api';

import { SSOServiceProviderName } from '../../types';
import { AppleSSO } from './apple-sso';

import { config } from '@/config';

export const appleSSO = new AppleSSO();

export function useAppleSSO() {
  const pathname = usePathname();
  const redirectUri = `${config.BASE_URL}${API_ROUTES.V1.AUTH.APPLE.$CALLBACK(pathname)}`;

  const { searchParams, deleteExternalSearchParams } = useSearchParams();
  const code = searchParams.get('code');

  const { data, error, isLoading } = useHttpQuery<
    {
      clientId: string;
      redirectUri: string;
      code: string | null;
      providerType: SSOServiceProviderName;
    },
    { idToken: string }
  >({
    retry: false,
    method: 'POST',
    enabled: !!code,
    api: identityApi,
    url: '/social-exchanges',
    queryKey: ['apple-sso', code],
    data: {
      code,
      redirectUri,
      providerType: appleSSO.name,
      clientId: config.APPLE_OAUTH_CLIENT_ID,
    },
  });

  // Effect: Show error message
  useEffect(() => {
    if (!error) return;

    notification.error({
      title: 'Unable to use Apple SSO right now. Please try again later.',
      position: 'top-center',
      variant: 'filled',
    });
  }, [error]);

  /** Removes the code from the URL and invalidates the query cache */
  function cleanup() {
    deleteExternalSearchParams(['code']);
  }

  return {
    cleanup,
    appleSSO,
    isLoading,
    redirectUri,
    idToken: data?.idToken,
  };
}
