'use client';

import { InitiateAuthentication } from '@/features/authentication/shared/components/initiate-authentication';
import { usePhoneNumberForm } from '@/features/authentication/signin/hooks/use-phone-number-form';
import { useSigninFormConfigContext } from '@/features/authentication/signin/providers/sign-in-form-context-provider';

import { Page, PageLoader, PageTitle } from '@/ui/components/page';

export default function SigninPage() {
  const { config } = useSigninFormConfigContext();
  const { title, subtitle, ctaText, ssoEnabled } = config.phoneNumberForm;

  const { handleSignin, errors, control } = usePhoneNumberForm();

  return (
    <Page>
      <PageTitle title={title} subtitle={subtitle} />
      <InitiateAuthentication
        {...{
          flow: 'signin',
          ssoEnabled,
          ctaText,
          handleSubmit: handleSignin,
          errors,
          control,
        }}
      />

      {config?.data?.isSSO && <PageLoader />}
    </Page>
  );
}
