import { SocialButton, SocialButtonProps } from '@kamona/components';

import { AppleLogo } from '@/ui/svgs/apple-logo';

import { cn } from '@/lib/utils';

export type AppleButtonProps = SocialButtonProps;

export function AppleButton({
  className,
  ...props
}: Readonly<AppleButtonProps>) {
  return (
    <SocialButton
      block
      aria-label="Apple SSO"
      size="medium"
      variant="outline"
      icon={<AppleLogo />}
      className={{
        ...className,
        root: cn(
          'border-neutral-s3 bg-grey/L2 text-content-heading-default',
          className?.root,
        ),
      }}
      {...props}
    >
      Apple
    </SocialButton>
  );
}
