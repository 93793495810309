import { Flex } from '@/ui/components/flex';
import { AppleButton, GoogleButton } from '@/ui/components/social-button';

export function SsoAccess() {
  return (
    <Flex gapX="units-unit14">
      <GoogleButton
        className={{
          root: 'border-neutral-s3 bg-neutral-s2 text-content-body-default font-semibold',
        }}
      />
      <AppleButton
        className={{
          root: 'border-neutral-s3 bg-neutral-s2 text-content-body-default font-semibold',
        }}
      />
    </Flex>
  );
}
