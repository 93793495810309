import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

import { GoogleSSO } from './google-sso';

import { config } from '@/config';

export const googleSSO = new GoogleSSO();

export function useGoogleSSO() {
  const router = useRouter();
  const pathname = usePathname();
  const redirectUri = `${config.BASE_URL}${pathname}`;
  const [idToken, setIdToken] = useState<string | null>(null);

  // Effect: Get idToken from URL hash
  useEffect(() => {
    const hash = window.location.hash;
    setIdToken(googleSSO.getIdTokenFromHash(hash));
  }, []);

  /** Removes the idToken from the URL */
  function cleanup() {
    router.replace(pathname);
  }

  return {
    idToken,
    cleanup,
    googleSSO,
    redirectUri,
  };
}
